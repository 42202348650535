<template>
  <div>
    <v-row>
      <v-col>
        <v-card class="mx-auto">
          <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header
                class="primary headline text-left white--text"
              >Confirm Business</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card class="elevation-3">
                  <v-card-text class="pa-4">
                    <!-- current businesses -->
                    <h2 v-if="!loading && userBusinesses.length == 0">No businesses to confirm</h2>
                    <v-data-table
                      v-if="!loading && userBusinesses.length > 0"
                      :headers="filteredHeaders"
                      :items="userBusinesses"
                      :loading="loading"
                      class="pt-4"
                      dense
                    >
                      <template v-slot:item.confirm="{item}">
                        <v-tooltip bottom>
                          <template v-slot:activator="{on}">
                            <v-btn
                              small
                              color="primary"
                              v-on="on"
                              icon
                              @click="confirmBusiness(item)"
                            >
                              <v-icon class="clickable" color="primary">mdi-check</v-icon>
                            </v-btn>
                          </template>
                          <span>Confirm Business</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-card-text>
                  <v-card-actions></v-card-actions>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog persistent v-model="dialog" width="350">
      <v-card class="elevation-3">
        <v-card-title class="primary headline white--text">Confirm?</v-card-title>
        <v-card-text class="pa-4">
          <v-row>
            <v-col sm="12">Confirm Access to business {{businessName}}?</v-col>
          </v-row>
          <v-card-actions>
            <v-btn color="primary" @click="dialog = !dialog">Cancel</v-btn>
            <v-btn @click="callConfirm" outlined color="primary">Confirm</v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { authMethods, authComputed } from '@state/helpers'
import { get, sync, commit, call } from 'vuex-pathify'
export default {
  page: {
    title: '',
  },
  //model: {
  //	prop: ''
  //},
  props: {},
  components: {},
  filters: {},
  data() {
    return {
      userBusinesses: [],
      panel: [0, 1],
      loading: false,
      dialog: false,
      businessName: '',
      selectedBusiness: {},
    }
  },
  created() {
    this.getBusinessesToConfirm()
  },
  computed: {
    ...authComputed,
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'confirm',
          visible: true,
          sortable: false,
        },
        {
          text: 'ID',
          align: 'center',
          sortable: true,
          value: 'ID',
          visible: false,
        },
        {
          text: 'Business Name',
          align: 'center',
          sortable: true,
          value: 'Business.BusinessName',
          visible: true,
        },
        {
          text: 'Address',
          align: 'center',
          sortable: true,
          value: 'Business.PhysicalAddress',
          visible: true,
        },
      ]
    },
  },
  methods: {
      ...authMethods,
    handleError: call('errors/handleError'),
    confirmBusiness(item) {
      this.businessName = item.Business.BusinessName
      this.selectedBusiness = item.Business
      this.dialog = true
    },
    getBusinessesToConfirm() {
      this.loading = true
      let url = `UserBusiness/Unconfirmed`
      this.$axios.get(url).then(
        (res) => {
          this.userBusinesses = res.data
          this.loading = false
        },
        (error) => {
          this.handleError(error)
          this.loading = false
          // this.error = error
          // this.displayError = true
          console.log(error)
        }
      )
    },
    callConfirm() {
      let url = `UserBusiness/Confirm/${this.selectedBusiness.ID}`
        this.$axios.get(url).then(
          (res) => {
      //refresh user and confirm list
            this.getBusinessesToConfirm()
            this.refreshCurrentUser()
            this.dialog = false
        },
        (error) => {
          this.handleError(error)
          // this.error = error
          // this.displayError = true
          console.log(error)
        }
      )
    },
  },
  watch: {
    userBusinesses(nval, oval) {
        console.log(nval)
      if (nval && nval.length > 0) {
        if (this.$route.params.businessId) {
          let business = nval.find(
            (i) => i.BusinessID == parseInt(this.$route.params.businessId)
          )
          if (business) {
            this.confirmBusiness(business)
          }
        }
      }
    },
  },
}
</script>
<style scoped>
</style>